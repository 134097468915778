import { configureStore } from '@reduxjs/toolkit';
import vitalsMeasurementsReducer from './state/vitals-measurements';
import consentReducer from './state/consent';

export const store = configureStore({
  reducer: {
    vitalsMeasurements: vitalsMeasurementsReducer,
    consent: consentReducer,
  },
});
