import React from 'react';

import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import withRouter from '../components/with-router';
import AppBar from '../components/app-bar';
import withStyles from '../components/with-styles';

const styles = {
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 30,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 30,
  },
  button: {
    backgroundColor: colors.green,
    height: 50,
    borderRadius: 25,
    borderStyle: 'none',
    width: '100%',
    color: 'white',
    fontSize: fontSizing.body,
    maxWidth: desktopMaxWidth,
  },
  logo: {
    maxWidth: 400,
    margin: '30px auto',
    width: '100%',
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontSize: fontSizing.body,
  },
  pageContainer: {
    flexGrow: 1,
  },
  pageContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    paddingLeft: 20,
    paddingRight: 20,
    '& p': {
      fontSize: fontSizing.body,
      textAlign: 'center',
    },
  },
  h1: {
    fontSize: 24,
    marginTop: 50,
    textAlign: 'center',
  },
  image: {
    maxWidth: 500,
    width: '100%',
  },
  imageRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  instructionsColumnLeft: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    paddingRight: 10,
  },
  instructionsColumnRight: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    paddingLeft: 10,
  },
  instructionsRow: {
    display: 'flex',
    marginBottom: 30,
  },
  instructionsTextGreen: {
    color: colors.green,
    fontStyle: 'italic',
    padding: 10,
    textAlign: 'center',
  },
  instructionsTextRed: {
    color: colors.red,
    fontStyle: 'italic',
    padding: 10,
    textAlign: 'center',
  }
}

const Welcome = (props) => {
  const { classes, navigate } = props;

  const handleClick = () => {
    navigate('/measure-vitals');
  };

  return (
    <div className={classes.root}>
      <AppBar title="Instructions" />
      <div className={classes.pageContent}>
        <h1 className={classes.h1}>Instructions</h1>
        <p>
          Place your device on a stand and on a firm,
          stable surface so that it is not moving while measuring your vitals.
          If you do not have a stand, place your device up against something
          with a solid backing so that it doesn’t move.
        </p>
        <hr/>
        <p>Fit face in Guidebox</p>
        <div className={classes.instructionsRow}>
          <div className={classes.instructionsColumnLeft}>
            <img className={classes.image} src="/web/assets/vt_good.png" alt="good face placement" />
            <div className={classes.instructionsTextGreen}>Good</div>
          </div>
          <div className={classes.instructionsColumnRight}>
            <img className={classes.image} src="/web/assets/vt_bad_toosmall.png" alt="face too small" />
            <div className={classes.instructionsTextRed}>Don't try to fit your hair</div>
          </div>
        </div>
        <p>Keep looking at the camera</p>
        <div className={classes.instructionsRow}>
          <div className={classes.instructionsColumnLeft}>
            <img className={classes.image} src="/web/assets/vt_good.png" alt="good face placement" />
            <div className={classes.instructionsTextGreen}>Good</div>
          </div>
          <div className={classes.instructionsColumnRight}>
            <img className={classes.image} src="/web/assets/vt_bad.png" alt="face looking away" />
            <div className={classes.instructionsTextRed}>Don't look away</div>
          </div>
        </div>
        <p>Stay still</p>
        <div className={classes.instructionsRow}>
          <div className={classes.instructionsColumnLeft}>
            <img className={classes.image} src="/web/assets/vt_good.png" alt="good face placement" />
            <div className={classes.instructionsTextGreen}>Good</div>
          </div>
          <div className={classes.instructionsColumnRight}>
            <img className={classes.image} src="/web/assets/vt_bad_motion.png" alt="face moving" />
            <div className={classes.instructionsTextRed}>Don't move</div>
          </div>
        </div>
      </div>
      <div className={classes.buttonWrapper}>
        <button
          onClick={handleClick}
          className={classes.button}
          role="link"
        >
          START
        </button>
      </div>
    </div>
  );
};

export default withStyles(styles)(withRouter(Welcome));
