import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  HR: '--',
  BR: '--',
  BP_SYS: '--',
  BP_DIA: '--',
  SPO2: '--',
};

export const vitalsMeasurementsSlice = createSlice({
  name: 'vitalsMeasurements',
  initialState,
  reducers: {
    updateVitals: (state, { payload }) => {
      const { HR, BR, BP_SYS, BP_DIA, SPO2 } = payload;
      state.HR = HR || '--';
      state.BR = BR || '--';
      state.BP_SYS = BP_SYS || '--';
      state.BP_DIA = BP_DIA || '--';
      state.SPO2 = SPO2 || '--';
    },
    resetVitals: () => {
      return initialState
    }
  },
});

export const { resetVitals, updateVitals } = vitalsMeasurementsSlice.actions;

export default vitalsMeasurementsSlice.reducer;
