export const colors = {
  primaryColor: '#207bcc',
  primaryHighlight: '#ecf5fe',
  primaryAccent: '#596097',
  secondaryColor: '#fd6120',
  secondaryAccent: '#de4f18',
  darkAccent: '#3c3d3f',
  highlightBright: '#edeef0',
  highlightMedium: '#b8c5d0',
  highlightDark: '#9da4ad',
  highlightDarker: '#3c4c65',
  successGreen: '#4eda1f',
  background: '#edf5fe',
  errorRed: '#dc3636',
  white: '#ffffff',
  blueGray: '#7f8fa4',
  blueSelect: '#68C2DA',
  btnBlue: '#0098cf',
  bottomNavigationHighlighted: '#0098d0',
  charcoal: '#595959',
  almostBlack: '#333333',
  darkGrayText: '#9b9b9b',
  highlightGray: '#edf2f5',
  lightGrayText: '#d8d8d8',
  fieldLabelGray: '#66676c',
  formTextBlue: '#0098d0',
  calendarGray: '#bac2c7',
  calendarGreen: '#309940',
  primaryGreen: '#309940',
  calendarYellow: '#eea321',
  calendarRed: '#e54353',
  errorOrange: '#de4f18',
  imageBlue: '#0098d0',
  imageGreen: '#c4c626',
  linearProgressPurple: '#800080',
  progressCircleYellow: 'rgba(198,205,96,0.6)',
  questionBackground: '#f5f5f5',
  teaGreen: '#5bae81',
  black: '#000000',
  boxShadowOutline: '#ceced2',
  lightGreyText: '#555555',
  painSelectionGrey: '#cbcbcb',
  painSelectionHeaderBackground: '#959595',
  backgroundImportColor: '#8fbde5',
  lightBrightBlue: '#3ebcf9',
  painBlue: '#5f88ab',
  checkIORed: '#E94E30',
  healthyRed: '#CD3636',
  comingSoonHealthComponent: ' #9D9D9D',
  greyText: '#9b9b9b',
  lightGreen: '#acdad2',
  green: '#2ea490',
  lightRed: '#e19bab',
  red: '#b30631',
  lightGrey: '#eeeeee',
};

export const fontSizing = {
  small: 10,
  body: 18,
  large: 32,
};

export const desktopMaxWidth = 700;
