import React from 'react';
import {createUseStyles} from 'react-jss';

const withStyles = (styles) => {
  const useStyles = createUseStyles(styles);

  const createWrappedComponent = (Component) => {
    const Wrapper = (props) => {
      const classes = useStyles();

      return <Component {...props} classes={classes} />
    }

    return Wrapper;
  }

  return createWrappedComponent;
}

export default withStyles;
