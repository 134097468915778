/* eslint-disable eqeqeq */
import rawr from 'rawr';
import transport from 'rawr/transports/worker';

// const TIME_THREASHOLD_MS = 60000;
// const MIN_TIME_THRESHOLD_MS = 60000;
// const MAX_TIME_THRESHOLD_MS = 90000;
const MAX_TIME_THRESHOLD_MS = 65000;
const MAX_TIME_THRESHOLD_MS_FOR_DISPLAY = 60000;
const MIN_FRAME_COUNT = 550;
const TARGET_FRAME_INTERVAL_MS = 28;

const STANDARD_CROP_HEIGHT = 300;
const STANDARD_CROP_WIDTH = 225;
const STANDARD_EDGE_SIZE = 50;
const LINE_WIDTH_PCT = 0.01;

let preparationVideoRAF;

let onframeTimer;
let onframeRAF;
let downloadTimer;

let startSession;
let endSession;
let detectVitals;
let getConfigString;
let previewSession;
let previewing = false;
let needSegmentedImage = false;

let rawrPeer;
let webWorker;

let start_timestamp_ms = -1;

let cacheFrames = false;
let frameCount = 0;
let cachedFrameCount = 0;
let processedFrameCount = 0;
let processingLeft = 0;
let processing = false;

let lastSigns;
let lastTrackingInfo;
let tracking = false;
let algorithmConfig = {};
let onceReady = false;

let startTimestamp;

let front_timestamps = fixedLengthArray(30);
let rear_timestamps = fixedLengthArray(30);
let detectTimestamps = [];
let responseTimestamps = [];

let shouldProcessFrame = true; // flag used to prevent frame collecting and processing while vitals collection restarts
let devMode = false;
let preventSessionOnUnmount = false;
let guideBoxColor = 'green';

// FaceDetector Options
const EXTERNAL_SKIP_FRAME = 30;
let externalFaceDetection = true;
let useFaceMesh = false;
let bboxHeightAdjustment = 33.0;
let bboxWidthAdjustment = 0.0;

let fax, fay, faw, fah;
// let detectionTimestamp = 0;
let detector;

// used to prevent starting a new session before endSession() returns if a user navigates away
// from the video vitals page and comes back, either from using browser controls or clicking
// the retry button
let resetting = false;

export const loadDetector = async () => {
  if (!externalFaceDetection)
  {
    detector = null;
    console.log("Use internal face detection")
  }
  else if (useFaceMesh)
  {
    const model = window.faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
    const detectorConfig = {
      runtime: 'mediapipe',
      solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh',
      maxFaces: 1,
    };
    detector = await window.faceLandmarksDetection.createDetector(model, detectorConfig);
    console.log("MediaPipe FaceMesh Detector Loaded")
  }
  else
  {
    const model = window.faceDetection.SupportedModels.MediaPipeFaceDetector;
    const detectorConfig = {
      runtime: 'mediapipe',
      solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/face_detection',
    };
    detector = await window.faceDetection.createDetector(model, detectorConfig);
    console.log("MediaPipe Face Detector Loaded")
  }
};

let faceDetectionCounter = EXTERNAL_SKIP_FRAME;

function fixedLengthArray(length) {
  var array = [];
  array.push = function () {
    if (this.length >= length) {
        this.shift();
    }
    return Array.prototype.push.apply(this,arguments);
  }
  return array;
}

export function getDimensions(video) {
  const scale = STANDARD_CROP_HEIGHT / video.videoHeight;
  const lineWidth = Math.round(LINE_WIDTH_PCT * video.videoHeight);

  // const pvH = STANDARD_CROP_HEIGHT;
  // const pvW = Math.round((pvH / video.videoHeight) * video.videoWidth);
  const scaledW = Math.round((STANDARD_CROP_HEIGHT / video.videoHeight) * video.videoWidth);
  const STANDARD_DISPLAY_WIDTH = Math.round((STANDARD_CROP_WIDTH * video.videoHeight) / STANDARD_CROP_HEIGHT);
  
  const displayCropH = video.videoHeight;
  const displayCropW = Math.min(video.videoWidth, (video.videoHeight * STANDARD_CROP_WIDTH) / STANDARD_CROP_HEIGHT);

  const cropH = STANDARD_CROP_HEIGHT;
  const cropW = Math.min(scaledW, STANDARD_CROP_WIDTH);

  let cropX = (scaledW / 2) - (cropW / 2);
  let displayCropX = (video.videoWidth / 2) - (displayCropW / 2);

  if (scaledW > STANDARD_CROP_WIDTH) {
    cropX = (scaledW / 2) - (STANDARD_CROP_WIDTH / 2);
    displayCropX = (video.videoWidth / 2) - (STANDARD_DISPLAY_WIDTH / 2);
  }

  const edgeSize = Math.round((STANDARD_EDGE_SIZE * video.videoHeight) / STANDARD_CROP_HEIGHT);

  const cropY = 0;
  const displayCropY = 0;
  const overlayX = displayCropX + edgeSize;
  const overlayY = displayCropY + edgeSize;
  const overlayW = displayCropW - (edgeSize * 2);
  const overlayH = displayCropH - (edgeSize * 2);
  const dimensions = {
    displayCropH,
    displayCropW,
    dipslayW: video.videoWidth,
    cropH,
    cropW,
    scaledW,
    displayCropY,
    displayCropX,
    cropY,
    cropX,
    overlayX,
    overlayY,
    overlayW,
    overlayH,
    lineWidth,
    edgeSize,
    scale,
  };
  return dimensions;
}

export function toggleDevMode() {
  devMode = !devMode;
}

export function setGuideBoxColor(color) {
  guideBoxColor = color;
}

export function killWebWorker() {
  webWorker.terminate();
  webWorker = null;
  clearTimeout(onframeTimer);
  clearInterval(downloadTimer);
}

function stopTimer() {
  clearInterval(downloadTimer);
}

function reset(noResettingCacheFrames) {
  stopTimer();
  cacheFrames = noResettingCacheFrames ? cacheFrames : false;
  tracking = false;
  start_timestamp_ms = -1;

  frameCount = 0;
  cachedFrameCount = 0;
  processedFrameCount = 0;
  front_timestamps.length = 0;
  rear_timestamps.length = 0;
  detectTimestamps = [];
  responseTimestamps = [];

  lastTrackingInfo = null;
  processingLeft = 0;
  lastSigns = null;
  lastTrackingInfo = null;
  startTimestamp = null;

  preventSessionOnUnmount = false;
  guideBoxColor = 'green';
  resetting = false;
}

export function setVitalsRunnerStateForReload() {
  cacheFrames = false;
  tracking = false;
  start_timestamp_ms = -1;

  frameCount = 0;
  cachedFrameCount = 0;
  processedFrameCount = 0;
  front_timestamps.length = 0;
  rear_timestamps.length = 0;
  detectTimestamps = [];
  responseTimestamps = [];

  lastTrackingInfo = null;
  processingLeft = 0;
  lastSigns = null;
  lastTrackingInfo = null;
  startTimestamp = null;

  guideBoxColor = 'green';
  resetting = false;
  onceReady = false;
}

async function endSessionJS(callbacks) {
  const {
    vrOnend,
    vrOnprocessing,
  } = callbacks;

  console.log("Done processing all frames");

  clearTimeout(onframeTimer);
  let finalSigns = await endSession();
  // convert from bigint to number
  finalSigns.timestamp = Number(finalSigns.timestamp);
  finalSigns.detectTimestamps = detectTimestamps;
  finalSigns.responseTimestamps = responseTimestamps;
  console.log("Session ended. Final results: ", finalSigns);
  processing = false;
  if (vrOnprocessing) {
    vrOnprocessing(false);
  }
  if (vrOnend) {
    vrOnend(finalSigns);
  }

  reset();
}

// Front camera
function detectVitalsJS(imgBitmap, width, height, timestamp, segmentedImageNeeded) {
  if (cachedFrameCount === 0) {
    start_timestamp_ms = timestamp;
  }

  const vitalsCollected = enabledVitalsCollected();
  const elapsedTime = (timestamp - start_timestamp_ms);
  if ((elapsedTime <= MAX_TIME_THRESHOLD_MS && !vitalsCollected)
     || frameCount < MIN_FRAME_COUNT
     || previewing) {   
    let frameInfo = {
      width: width,
      height: height,
      timestamp: timestamp
    }
    detectTimestamps.push(Date.now());

    const faceInfo = {
      height: fah,
      width: faw,
      x: fax,
      y: fay,
      use: externalFaceDetection
    };
  
    // detectVitals(frameInfo, imgData, faceInfo); // async from webworker
    detectVitals(frameInfo, imgBitmap, faceInfo, segmentedImageNeeded, { postMessageOptions: [imgBitmap] }); // async from webworker

    cachedFrameCount++; // only keeping count for front camera

    return true; // continue caching
  } else {
    return false; // caching complete
  }
}

function enabledVitalsCollected() {
  if (lastSigns == null) return;
  let enabledVitalsCollected = true;
  if (true) {
    enabledVitalsCollected &= lastSigns.hrSignalPercent == 1.0;
  }
  if (true) {
    enabledVitalsCollected &= lastSigns.brSignalPercent == 1.0;
  }
  if (false) {
    enabledVitalsCollected &= lastSigns.bpSignalPercent == 1.0;
  }
  if (false) {
    enabledVitalsCollected &= lastSigns.spo2SignalPercent == 1.0;
  }
  return enabledVitalsCollected;
}

function setTimeBar(timeLeft, vrOntimeLeft) {
  const displayTimeLeft = timeLeft - ((MAX_TIME_THRESHOLD_MS - MAX_TIME_THRESHOLD_MS_FOR_DISPLAY) / 1000);
  const percentLeft = displayTimeLeft / (MAX_TIME_THRESHOLD_MS_FOR_DISPLAY / 1000);
  if (vrOntimeLeft) {
    vrOntimeLeft(displayTimeLeft, percentLeft);
  }
}

function startTimer(callbacks) {
  const {
    vrOnprocessing,
    vrOntimeLeft,
  } = callbacks;

  let timeLeft = MAX_TIME_THRESHOLD_MS / 1000;
  downloadTimer = setInterval(() => {
    if (timeLeft <= 0) {
      setTimeBar(0, vrOntimeLeft);
      clearInterval(downloadTimer);

      if (processingLeft !== 0) {
        processing = true;
        if (vrOnprocessing) {
          vrOnprocessing(processing);
        }
      }
    } else {
      setTimeBar(timeLeft, vrOntimeLeft);
    }
    timeLeft -= 1;
  }, 1000);
}

export async function startSessionJS(callbacks) {
  await endPreview();
  const {
    vrOnprocessing,
    vrOntimeLeft,
  } = callbacks;

  await startSession(true, true, false, false);
  Object.assign(algorithmConfig, JSON.parse(await getConfigString()));
  console.log(algorithmConfig)
  shouldProcessFrame = true;
  startTimer({ vrOnprocessing, vrOntimeLeft });
  cacheFrames = true;
}

export const isWebWorkerReady = () => {
  return !resetting && onceReady;
};

export const createVitalsRunnerWebWorker = () => {
  if (!webWorker) {
    webWorker = new window.Worker('web/scripts/worker.js');
    webWorker.onerror = (err) => {
      killWebWorker();
      console.error('webworker error', err);
    };

    rawrPeer = rawr({ transport: transport(webWorker) });
    ({ startSession, endSession, detectVitals, getConfigString, previewSession } = rawrPeer.methods);

    rawrPeer.notifications.onready(() => {
      if (!onceReady) {
        onceReady = true;
      }
    });
  }
};

export const addCallbacksToWebWorker = (callbacks) => {
  const {
    vrOnsigns,
    vrOnprocessing,
    vrOnend,
  } = callbacks;

  if (!webWorker) createVitalsRunnerWebWorker();

  webWorker.onmessage = (event) => {
    let msg;
    try {
      msg = JSON.parse(JSON.stringify(event.data, (key, value) => {
        // eslint-disable-next-line
        if (typeof value === 'bigint') {
          return Number(value);
        }
        return value;
      }));
    } catch (e) {
      console.error('unable to serialize msg', e);
    }
    if (!msg) {
      return;
    }
    switch (msg.type) {
      case "signs":
        if (shouldProcessFrame) {
          responseTimestamps.push(Date.now());
          processedFrameCount++;

          lastSigns = msg.data.signs;
          needSegmentedImage = lastSigns.needSegmentedImage;

          let trackingInfo = msg.data.trackingInfo;

          if (!tracking && (lastSigns.trackingHR === 1 || lastSigns.trackingBR === 1)) {
            console.log("tracking started");
            tracking = true;
          }

          if (tracking && (lastSigns.trackingHR === 0 || lastSigns.trackingBR === 0)) { 
            console.log("tracking lost");
            reset();
          }

          if (trackingInfo) {
            lastTrackingInfo = trackingInfo;
          }

          if (!cacheFrames && processedFrameCount === cachedFrameCount) {
            endSessionJS({ vrOnend, vrOnprocessing });
          }

          msg.data.cachedFrameCount = cachedFrameCount;
          msg.data.processedFrameCount = processedFrameCount;

          if (vrOnsigns) {
            vrOnsigns(msg.data);
          }
        }

        break; 
      case "redChannel":
        console.log(msg.data);
        break;
      default:
        // console.log('invalid message', msg);
    }
  };
};

export default async function vitalsRunner(video, canvas, patientCanvasVisible, callbacks) {
  const {
    vrOnprocessing,
    vrOnend,
    vrGetCoreWarnings,
  } = callbacks;

  const {
    displayCropH,
    dipslayW,
    cropW,
    displayCropY,
    displayCropX,
    lineWidth,
    edgeSize,
  } = getDimensions(video);

  console.log('***** in vitalsRunner')

  let processFaceDetection = true;
  shouldProcessFrame = true;

  await initialSessionPreview();

  async function detectFaceLoop() {
    if (!detector) {
      setTimeout(() => detectFaceLoop(), 100);
      return 0;
    }

    if (!processFaceDetection) {
      return 0;
    }
  
    const estimationConfig = {flipHorizontal: false};
    try {
      if (faceDetectionCounter++ >= EXTERNAL_SKIP_FRAME) {
        const faces = await detector.estimateFaces(video, estimationConfig);
        if (faces && faces.length) {
          let box = faces[0].box;
          // draw or use these 4 box corners for the face
          let widthAdjustment = bboxWidthAdjustment / 100;
          let heightAdjustment = bboxHeightAdjustment / 100;
      
          let cX = (box.xMax + box.xMin) / 2;
          fax = box.xMin - (cX - box.xMin) * widthAdjustment;
          fay = box.yMin - box.height * heightAdjustment;
          faw = box.width * (1 + widthAdjustment);
          fah = box.height * (1 + heightAdjustment);
          // console.log({fax, faw, fay, fah});
          faceDetectionCounter = 0
        } else {
          fax = 0
          fay = 0
          fah = 0
          faw = 0
        }
      }
    } catch (e) {
      console.warn('error processing face detection', e);
    }
    return 0;
    // setTimeout(() => detectFaceLoop());
  }

  const ctx = patientCanvasVisible.getContext('2d');
  const offscreenCtx = canvas.getContext('2d');

  // If restarting frame collection, the existing onframeTimer needs to be cleared
  // so that multiple loops executing onframe are not running concurrently
  clearTimeout(onframeTimer);
  // detectFaceLoop();
  let canvasSized = false;

  const onframe = async () => {
    let timestamp = Date.now();

    if (video.videoWidth > 0) {
      await detectFaceLoop();
      if (!canvasSized) {
        canvas.height = video.videoWidth;
        canvas.width = video.videoHeight;
        canvasSized = true;
      }
      offscreenCtx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

      if (cacheFrames && shouldProcessFrame) {
        front_timestamps.push(timestamp);
        ++frameCount;
        if (!startTimestamp) {
          startTimestamp = timestamp;
          console.log('Starting timestamp:', startTimestamp);
        }

        const imgBitmap = await createImageBitmap(offscreenCtx.canvas, 0, 0, video.videoWidth, video.videoHeight);

        cacheFrames = detectVitalsJS(imgBitmap, video.videoWidth, video.videoHeight, timestamp, needSegmentedImage);
        needSegmentedImage = false;

        processingLeft = cachedFrameCount - processedFrameCount;

        if (!cacheFrames) {
          console.log("end_timestamp: " + timestamp 
            + "  ...duration: " + (timestamp - start_timestamp_ms));
          processFaceDetection = false;  
            
          if (processedFrameCount === cachedFrameCount) {
            processing = false;
            endSessionJS({ vrOnend, vrOnprocessing });
          }
        }
      }

      if (cropW > 0) {
        const strokeColor = guideBoxColor;
        ctx.drawImage(video, 0, 0, dipslayW, displayCropH);
        ctx.strokeStyle = strokeColor;
        ctx.lineWidth = lineWidth;
        if (!processing) {
          // ctx.strokeRect(overlayX, overlayY, overlayW, overlayH);
          ctx.stroke();
        }
        if (devMode) {
          ctx.setLineDash([15, 15]);
          ctx.setLineDash([]);
          if (lastSigns && (lastSigns.snrBR || lastSigns.snrHR)) {
            const {
              snrBR,
              snrHR,
              lightingScore,
              motionScore,
              qualityScore,
              dataStatus,
            } = lastSigns;
            ctx.save();
            ctx.translate(dipslayW, 0);
            ctx.scale(-1, 1);
            const snrStr = `SNR_HR: ${snrHR ? Number(snrHR).toFixed(2) : ''} SNR_BR: ${snrBR ? Number(snrBR).toFixed(2) : ''}`;
            const motionStr = `light: ${lightingScore} motion: ${motionScore} quality: ${qualityScore}`;
            const dataStatusStr = `status: ${dataStatus}`;
            let activeCoreWarningsStr = 'warning codes: ';
            const activeCoreWarnings = vrGetCoreWarnings();
            if (activeCoreWarnings.length) {
              const warningCodeStr = activeCoreWarnings.map(warning => warning.code).join(',');
              activeCoreWarningsStr += warningCodeStr;
            }
            ctx.fillStyle = 'yellow';
            ctx.font = `${Math.round(edgeSize * 0.2)}px serif`;
            ctx.fillText(snrStr, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) - 5);
            ctx.fillText(motionStr, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) + 9);
            ctx.fillText(dataStatusStr, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) + 23);
            ctx.fillText(activeCoreWarningsStr, displayCropX + (edgeSize * 0.7) , (displayCropY + (edgeSize * 0.7)) + 37);
            ctx.restore();
          }

          if (lastTrackingInfo) {
            lastTrackingInfo.forEach((t) => {
              if (t.boxes) {
                t.boxes.forEach((b) => {
                  const tx = Math.round(b.xmin);
                  const tw = Math.round(b.xmax - b.xmin);
                  const ty = Math.round(b.ymin);
                  const th = Math.round(b.ymax - ty);
                  if (t.type === 'FACE') {
                    ctx.strokeStyle = 'blue';
                  } else if (t.type === 'ROI') {
                    ctx.strokeStyle = 'red';
                  }
                  ctx.strokeRect(tx, ty, tw, th);
                });
              }
            });
          }
        }
      }
    }
    onframeTimer = setTimeout(onframe, TARGET_FRAME_INTERVAL_MS);
  };

  cancelAnimationFrame(preparationVideoRAF);

  if (preventSessionOnUnmount) {
    // this addresses a bug where startSession ends up being called if there is an error
    // causing a reset, and a user clicks the back button during the reset which should
    // end the session
    // NOTE - automatic restarts are currently not being implemented
    preventSessionOnUnmount = false;
    return;
  }

  onframeRAF = requestAnimationFrame(onframe);
}

export async function restartSession(video, canvas, patientCanvasVisible, callbacks) {
  stopTimer();
  cacheFrames = false;
  shouldProcessFrame = false;
  resetting = true;
  await endSession();
  reset();
  vitalsRunner(video, canvas, patientCanvasVisible, callbacks);
}

export async function initialSessionPreview() {
  await previewSession();
  previewing = true;
  cacheFrames = true;
}

async function endPreview() {
  // if processing left, wait for it to finish
  const startTime = Date.now();
  const timeout = 1000;
  while (cachedFrameCount !== processedFrameCount) {
    if (Date.now() - startTime >= timeout) break;
    await new Promise(r => setTimeout(r, 100));
  }
  await endSession();
  // Handle preview end
  previewing = false;
  const noResettingCacheFrames = true;
  reset(noResettingCacheFrames);
}

// download timer is getting started
export async function resetVitalsRunnerOnUnmount() {
  preventSessionOnUnmount = true;
  stopTimer();
  clearTimeout(onframeTimer);
  shouldProcessFrame = false;
  resetting = true;
  await endSession();
  clearTimeout(onframeTimer);
  window.cancelAnimationFrame(onframeRAF);
  reset();
}

export const preparationVideo = async (video, patientCanvasVisible) => {
  const {
    displayCropH,
    dipslayW,
    lineWidth,
  } = getDimensions(video);

  return new Promise(async (resolve) => {
    const ctx = patientCanvasVisible.getContext('2d');

    const onframe = async () => {
      if (video.videoWidth > 0) {
        if (dipslayW > 0) {
          const strokeColor = guideBoxColor;
          ctx.drawImage(video, 0, 0, dipslayW, displayCropH);
          ctx.strokeStyle = strokeColor;
          ctx.lineWidth = lineWidth;
          // ctx.strokeRect(overlayX, overlayY, overlayW, overlayH);
          ctx.stroke();
        }
      }
      preparationVideoRAF = requestAnimationFrame(onframe);
    };

    // wait a bit because first few frames are always black when turning on camera.
    setTimeout(() => {
      preparationVideoRAF = requestAnimationFrame(onframe);
      resolve();
    }, 200);
  });
};

export const stopPreparationVideo = () => {
  cancelAnimationFrame(preparationVideoRAF);
};
