import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firstName: '',
  lastName: '',
};

export const consentSlice = createSlice({
  name: 'consent',
  initialState,
  reducers: {
    updateConsent: (state, { payload }) => {
      const { firstName, lastName } = payload;
      state.firstName = firstName;
      state.lastName = lastName;
    },
  },
});

export const { updateConsent } = consentSlice.actions;

export default consentSlice.reducer;
