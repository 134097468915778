export async function startVideo (me, constraints = {}, micOn = true) {
  const mediaConstraints = { ...{
    video: {
      facingMode: "user",
      width: { exact: 640 },
      height: { exact: 480 }
    },
    audio: true,
    whiteBalanceMode: 'none',
    exposureMode: 'none',
  }, ...constraints};
  const stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
  const videoTrack = stream.getVideoTracks()[0];
  const audioTrack = stream.getAudioTracks()[0];
  me.videoTrack = videoTrack;
  me.audioTrack = audioTrack;
  me.stream = stream;

  if (!micOn) me.audioTrack.enabled = false;
  return stream;
}
