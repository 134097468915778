import React from 'react';
import { connect } from 'react-redux';
import { Paper } from '@mui/material';

import { colors, fontSizing, desktopMaxWidth } from '../lib/styles';
import { HeartBeat, Lungs } from '../lib/icons';
import withStyles from '../components/with-styles';

const styles = {
  bodyText: {
    fontSize: fontSizing.body,
  },
  boldText: {
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: colors.green,
    height: 50,
    borderRadius: 25,
    borderStyle: 'none',
    width: '100%',
    color: 'white',
    fontSize: fontSizing.body,
    maxWidth: desktopMaxWidth,
  },
  icon: {
    objectFit: 'contain',
    width: 25,
    height: 25,
  },
  iconWrapper: {
    alignItems: 'center',
    boxShadow: '0 0 8px lightgrey',
    borderRadius: '50%',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    width: 50,
  },
  page: {
    background: colors.questionBackground,
    display: 'flex',
    flexDirection: 'column',
    fontSize: fontSizing.body,
    height: '100%',
  },
  pageContent: {
    boxSizing: 'border-box',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 540,
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%', // needed to make buttons on the bottom of the page span up to the maxWidth
  },
  vitalMeasurementTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: fontSizing.body,
    width: 150,
    marginLeft: 10,
  },
  vitalMeasurementValue: {
    fontSize: fontSizing.h1,
  },
  vitalsRed: {
    color: '#cd3636',
  },
  centerText: {
    textAlign: 'center',
  },
  vitalsMeasurement: {
    position: 'relative',
    zIndex: 2,
    color: 'black',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
  },
  vitalsMeasurementWrapper: {
    position: 'relative',
    borderRadius: 20,
    flexBasis: 0,
    flexGrow: 1,
    margin: 10,
    background: 'white',
    overflow: 'hidden',
  },
  vitalsMeasurementIconWrapper: {
    position: 'absolute',
    left: -20,
    zIndex: 1,
    height: 80,
    width: 80,
  },
  lungIconWrapper: {
    position: 'absolute',
    top: 10,
    left: -20,
    zIndex: 1,
    height: 80,
    width: 80,
  },
  vitalsMeasurementTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    zIndex: 2,
  },
  textBold: {
    fontWeight: 'bold',
  },
  textLarge: {
    fontSize: 32,
  },
  textBody: {
    fontSize: 18,
  },
  textSmall: {
    fontSize: 10,
  },
  textRed: {
    color: colors.red,
  },
  textGreen: {
    color: colors.green,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    marginTop: 40,
    textAlign: 'center',
  },
  disclaimer: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 16,
    marginTop: 20,
    textAlign: 'center',
  },
  vitalsValuesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  vitalMeasurementUnit: {
    position: 'absolute',
    right: 0,
    top: '90%',
    fontSize: 10,
  },
  subText: {
    marginBottom: 30,
    textAlign: 'center',
  },
  image: {
    maxWidth: 500,
    width: '100%',
    marginTop: 50,
  },
};

const paperStyles = {
  position: 'relative',
  boxShadow: '0 0 8px lightgrey',
  display: 'flex',
  marginBottom: (10 / 8),
  marginTop: (10 / 8),
  padding: (25 / 8),
  alignItems: 'center',
  overflow: 'hidden',
  borderRadius: (20 / 4),
};

const PaperStyled = ({ children }) => (
  <Paper sx={paperStyles}>{children}</Paper>
);

class Results extends React.Component {
  state = {
    showErrorScreen: false,
    submissionAttempts: 0,
    submitting: false,
  }

  handleClick = () => {
    document.location.href = '/';
  };

  render() {
    const {
      classes,
      HR,
      BR,
    } = this.props;

    const heartRate = HR > 0 ? HR : '--';
    const breathingRate = BR > 0 ? BR : '--';

    return (
      <div className={classes.pageContent}>
        <img className={classes.image} src="/web/assets/vital-trac.png" alt="Mindset Medical Logo" />
        <h1 className={classes.title}>Complete!</h1>
        {/* <p className={`${classes.subText} ${classes.textBody}`}>You may now close this window</p> */}

        <PaperStyled sx={styles.vitalsMeasurementContainer}>
          <div className={classes.vitalsMeasurementIconWrapper}>
            <HeartBeat style={{ color: colors.lightGrey }} />
          </div>
          <div className={classes.vitalsMeasurementTextContainer}>
            <div className={`${classes.textBody} ${classes.textBold}`}>heart rate</div>
            <div className={`${classes.textLarge} ${classes.textBold} ${classes.textRed}`}>{heartRate}</div>
            <div className={classes.vitalMeasurementUnit}>bpm</div>
          </div>
        </PaperStyled>
        <PaperStyled sx={styles.vitalsMeasurementContainer}>
          <div className={classes.lungIconWrapper}>
            <Lungs style={{ color: colors.lightGrey }} />
          </div>
          <div className={classes.vitalsMeasurementTextContainer}>
            <div className={`${classes.textBody} ${classes.textBold}`}>breathing rate</div>
            <div className={`${classes.textLarge} ${classes.textBold} ${classes.textGreen}`}>{breathingRate}</div>
            <div className={classes.vitalMeasurementUnit}>bpm</div>
          </div>
        </PaperStyled>
        <div>
          <div className={classes.buttonWrapper}>
            <button
              onClick={this.handleClick}
              className={classes.button}
              role="link"
            >
              START OVER
            </button>
            <h3 className={classes.disclaimer}>vital-trac is intended to inform you of your general wellbeing and is not meant to be a substitute of the clinical judgement of your health care provider. vital-trac is not meant to diagnose, treat, mitigate, or prevent any symptom, disorder, disease or abnormal physical condition. Please consult directly with your health care provider to discuss any medical conditions.</h3>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    vitalsMeasurements: {
      HR,
      BR,
    },
  } = state;

  return {
    HR,
    BR,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Results));
