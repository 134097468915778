import React, { useState } from 'react';

import GeneralLayoutScrollListener from '../components/general-layout-scroll-listener';
import withRouter from '../components/with-router';
import withStyles from '../components/with-styles';

const styles = {
  h1: {
    fontSize: 24,
    marginTop: 50,
    textAlign: 'center',
  },
  image: {
    maxWidth: 500,
    width: '100%',
    marginTop: 50,
  },
};

const Disclaimer = (props) => {
  const { classes, navigate } = props;
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const detectScrolledToBottom = () => {
      setButtonDisabled(false);
  };

  const handleClick = () => {
    navigate('/instructions');
  }

  return (
    <GeneralLayoutScrollListener
      title="Disclaimer"
      buttonText="I UNDERSTAND"
      buttonOnClick={handleClick}
      buttonDisabled={buttonDisabled}
      onScroll={detectScrolledToBottom}
    >
      <img className={classes.image} src="/web/assets/vital-trac.png" alt="Mindset Medical Logo" />
      <h1 className={classes.h1}>DISCLAIMER</h1>
      <p>The Vital-Trac™ application is designed strictly for general health and wellness education and engagement. It is not cleared by the U.S. Food and Drug Administration (FDA). It's crucial to understand that Vital-Trac™ should not be used as a replacement for the professional medical advice or clinical judgment of your healthcare provider.</p>
      <p>Vital-Trac™ does not have the capabilities to diagnose, treat, alleviate, or prevent any form of disease, symptom, disorder, or abnormal physical state. If you suspect that you may be dealing with a potential health issue, we highly recommend that you consult with your healthcare provider or seek immediate assistance from emergency medical services.</p>
    </GeneralLayoutScrollListener>
  )
};

export default withStyles(styles)(withRouter(Disclaimer));
