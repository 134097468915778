import React from 'react';

import { fontSizing } from '../lib/styles';

const styles = {
  appBar: {
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    fontSize: fontSizing.body,
  },
};

const AppBar = ({ title }) => (
  <div style={styles.appBar}>{title}</div>
)

export default AppBar;
